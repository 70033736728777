<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col>
              <v-select
                id="id_com_brand"
                v-model="id_com_brand"
                placeholder="Marka"
                :options="brands"
                label="title"
                :reduce="item => item.id"
                @input="getUsers(id_com_brand)"
              />
            </b-col>
            <b-col>
              <v-select
                id="id_com_user"
                v-model="id_com_user"
                placeholder="Danışman"
                :options="users"
                label="title"
                :reduce="item => item.id"
              >
                <template v-slot:option="option">
                  <div v-if="option.brand">
                    {{ option.title }}
                    <div>
                      <small class="text-warning">{{ option.brand }}</small>
                    </div>
                  </div>
                  <div v-else>
                    {{ option.title }}
                  </div>
                </template>
                <template v-slot:selected-option="option">
                  <div v-if="option.brand">
                    {{ option.title }}
                    <small class="text-warning">{{ option.brand }}</small>
                  </div>
                  <div v-else>
                    {{ option.title }}
                  </div>
                </template>
              </v-select>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-datepicker
                  id="invoice_date"
                  v-model="invoice_date"
                  v-bind="{labelNoDateSelected: 'Fatura Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
                  locale="tr"
                  start-weekday="1"
                />
                <b-input-group-append v-if="invoice_date">
                  <b-button

                    variant="warning"
                    @click="invoice_date = null"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="primary"
                @click="getSaleDeliveries"
              >
                <feather-icon icon="FilterIcon" />
                Filtrele
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="saleDeliveries"
          striped
          hover
        >
          <template #cell(customer)="data">
            <div>{{ data.item.customer }}</div>
            <div
              v-if="data.item.company"
              class="font-small-2 text-warning"
            >{{ data.item.company }}</div>
          </template>
          <template #cell(brand)="data">
            <div>
              {{ data.item.brand }}
            </div>
            <div class="font-small-2 text-primary">
              {{ data.item.chasis }}
            </div>
            <div class="font-small-2 text-warning">
              {{ data.item.licence_plate }}
            </div>
          </template>
          <template #cell(invoice_date)="data">
            <div>{{ moment(data.item.invoice_date).format('ll') }}</div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/sale_deliveries/add/' + data.item.id"
            >
              Oluştur
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="saleDeliveriesCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BCardBody, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BCardBody,
    vSelect,
    BInputGroupAppend,
    BFormDatepicker,
    BInputGroup,
  },
  data() {
    return {
      id_com_brand: null,
      id_com_user: null,
      invoice_date: null,
      currentPage: 1,
      perPage: 50,
      search: '',
      fields: [
        {
          key: 'customer',
          label: 'Müşteri Adı',
        },
        {
          key: 'brand',
          label: 'Marka',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'invoice_date',
          label: 'Fatura Tarihi',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
      dataQuery: {
        limit: 50,
        start: 0,
        saleDelivery: true,
        order_by: ['_mssql_aracsatis.invoice_date,com_sale_orderform.id', 'ASC'],
      },
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    saleDeliveries() {
      return this.$store.getters['saleDelivery/getSaleDeliveries']
    },
    saleDeliveriesCount() {
      return this.$store.getters['saleDelivery/getSaleDeliveriesCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 50
      this.pagination(page)
    },
  },
  created() {
    this.getBrands()
    this.getUsers()
    this.getSaleDeliveries()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getUsers(brand = null) {
      if (brand) {
        this.$store.dispatch('users/usersList', {
          select: [
            'com_user.id AS id',
            'com_user.name AS title',
            'com_user_type.title AS user_type',
            'com_brand.name AS brand',
          ],
          where: {
            'com_user.status': 1,
            'com_user.id_com_user_type': 5,
            'com_user.id_com_brand': brand,
          },
        })
      } else {
        this.$store.dispatch('users/usersList', {
          select: [
            'com_user.id AS id',
            'com_user.name AS title',
            'com_user_type.title AS user_type',
            'com_brand.name AS brand',
          ],
          where: {
            'com_user.status': 1,
            'com_user.id_com_user_type': 5,
          },
        })
      }
    },
    getSaleDeliveries() {
      if (this.id_com_brand) {
        this.dataQuery.id_com_brand = this.id_com_brand
      } else {
        this.dataQuery.id_com_brand = null
      }
      if (this.id_com_user) {
        this.dataQuery.id_com_user = this.id_com_user
      } else {
        this.dataQuery.id_com_user = null
      }
      if (this.invoice_date) {
        this.dataQuery.invoice_date = this.invoice_date
      } else {
        this.dataQuery.invoice_date = null
      }
      this.$store.dispatch('saleDelivery/saleDeliveriesList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getSaleDeliveries()
      }
    },
  },
}
</script>
